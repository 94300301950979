<template>
  <div>
    <div style="text-align: right; padding: 20px 20px 0 0;">
      <v-btn-toggle
        v-model="typeList"
        borderless
      >
        <v-btn value="presentations">
          <span class="hidden-sm-and-down">presentaciones</span>

          <!--v-icon right>
            mdi-format-align-left
          </v-icon-->
        </v-btn>

        <v-btn value="groups">
          <span class="hidden-sm-and-down">grupos</span>

          <!--v-icon right>
            mdi-format-align-center
          </v-icon-->
        </v-btn>
      </v-btn-toggle>
    </div>
    <list-presentation v-if="network && typeList === 'presentations'" :network="network"/>
    <list-group v-if="network && typeList === 'groups'" :network="network"/>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import api from '@/services/api'
import ListPresentation from './list/ListPresentation.vue'
import ListGroup from './list/ListGroup.vue'
export default {
  components: {
    ListPresentation,
    ListGroup,
  },
  data: () => ({
    network: null,
    typeList: 'presentations',
  }),
  computed: {
    ...mapState('app',['locale', 'playersWorkspace']),
    workspaceID () {
      return this.playersWorkspace
    }
  },
  watch: {
    playersWorkspace () {
      this.handleGetNetwork()
    },
  },
  mounted () {
    this.handleGetNetwork()
  },
  methods: {
    handleGetNetwork () {
      this.network = null
      if (!this.workspaceID) return
      api.getItem('players', `v1/private/workspaces/`, this.workspaceID.concat('/network'))
        .then(response => {
          this.network = response
        })
        .catch(error => {
          console.log(error)
        })
    },
  },
}
</script>

